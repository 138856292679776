a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.vmacLogo {
    display: inline-block;
    height: 103px;
    width: 300px;
    background-image: url("../images/vmac_logo.svg");
    background-repeat: no-repeat;
    background-position: center center;
}


.cuLogo {
    display: inline-block;
    height: 103px;
    width: 300px;
    background-repeat: no-repeat;
    background-position: center center;
}
